<template>
  <div class="index">
    <van-nav-bar
      title="客户意向审核"
      left-text="返回"
      left-arrow
      fixed
      right-text="筛选"
      :placeholder="true"
      @click-left="onClickLeft"
       @click-right="onClickRight"
    />
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="list.length? '没有更多了' : ''"
        @load="onLoad"
      >
        <div class="list-item" v-for="(item, index) in list" :key="index" @click="pushUserEmployee(item.contact_id, item.employee_id)">
          <van-tag v-if="item.audit == 2" type="success">已通过</van-tag>
          <van-tag v-if="item.audit == 3" type="danger">已驳回</van-tag>
          <van-tag v-if="item.audit == 1" type="warning">待审核</van-tag>
         
          <div class="list-item-content">
            <div>客户：{{ item.contact.name }}</div>
          </div>
          <div class="list-item-content">
            <div>申请时间：{{ item.created_at }}</div>
          </div>
          <div class="list-item-content" v-if="item.audit_time">
            <div>审核时间：{{ item.audit_time }}</div>
          </div>
          <div class="list-item-content">
            <div v-if="item.stage">更改等级：{{ item.stage.name }} <span style="color: #999" v-if="item.current_stage">（原等级-{{ item.current_stage.name }}）</span></div>
          </div>
          
          <div class="list-item-content">
            <div>申请原因：{{ item.reason }}</div>
          </div>
        </div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        description="暂无数据"
      />
    </div>

    <van-popup v-model="showRight" position="right" :style="{ height: '100%', width: '80%' }">
      <div class="screen">
        <div class="list-item">
          <div class="item-title">
            状态
          </div>
          <div>
            <div v-for="(item,index) in screenType" :key="index" @click="activeq(item.value)" :class="{qd: item.value === screenAudit}" class="item-content item-lable">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="list-item">
          <div class="item-title">
            申请时间
          </div>
          <div class="item-content">
            <div @click="showTime = true" style="flex: 1;text-align: center">{{ startDayStr ? startDayStr : '开始时间' }}</div>
            -
            <div @click="showTime = true" style="flex: 1;text-align: center">{{ endDayStr ? endDayStr : '结束时间' }}</div>
            <van-icon name="close" v-show="startDayStr || endDayStr" @click="clearTime('created')"/>
          </div>
        </div>
        <div class="list-item" v-if="screenAudit > 1">
          <div class="item-title">
            审核时间
          </div>
          <div class="item-content">
            <div @click="showAuditTime = true" style="flex: 1;text-align: center">{{ auditStartDayStr ? auditStartDayStr : '开始时间' }}</div>
            -
            <div @click="showAuditTime = true" style="flex: 1;text-align: center">{{ auditEndDayStr ? auditEndDayStr : '结束时间' }}</div>
            <van-icon name="close" v-show="auditStartDayStr || auditEndDayStr" @click="clearTime('audit')"/>
          </div>
        </div>
        <div class="">
          <van-button style="width: 100%" type="info" @click="onSearch">筛选</van-button>
        </div>
      </div>
    </van-popup>
    <van-action-sheet v-model="showTime" :close-on-click-overlay="false">
      <van-datetime-picker
        v-model="currentDate.startDay"
        type="date"
        title="开始日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <van-datetime-picker
        v-model="currentDate.endDay"
        type="date"
        title="结束日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <div class="showSelectDayBtn">
        <van-button @click="showTime = false">取消</van-button>
        <van-button type="info" @click="setDate('created')">确认</van-button>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="showAuditTime" :close-on-click-overlay="false">
      <van-datetime-picker
        v-model="currentDate.auditStartDay"
        type="date"
        title="开始日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <van-datetime-picker
        v-model="currentDate.auditEndDay"
        type="date"
        title="结束日期"
        :formatter="formatter"
        :visible-item-count="4"
      >
        <div slot="confirm"></div>
        <div slot="cancel"></div>
      </van-datetime-picker>
      <div class="showSelectDayBtn">
        <van-button @click="showAuditTime = false">取消</van-button>
        <van-button type="info" @click="setDate('audit')">确认</van-button>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant'
import { getCookie, pushUserEmployee } from '@/plugins/utils'
import { StageAudit } from '@/api/stageAudit'

export default {
  data () {
    return {
      pushUserEmployee,
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15,

      showRight: false,
      showTime: false,
      showAuditTime: false,
      currentDate: {
        startDay: new Date(),
        endDay: new Date(),
        auditStartDay: new Date(),
        auditEndDay: new Date(),
      },
      screenAudit: -1,
      startDayStr: '',
      endDayStr: '',
      auditStartDayStr: '',
      auditEndDayStr: '',
      showPicker: false,

      screenType: [
        {
          name: '全部',
          value: -1
        },
        {
          name: '不需审核',
          value: 0
        },
        {
          name: '待审核',
          value: 1
        },
        {
          name: '已通过',
          value: 2
        },
        {
          name: '已驳回',
          value: 3
        }
      ]
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
  methods: {
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      let param = {
        page: this.page,
        page_size: this.perPage,
      }
      if (this.screenAudit != -1) param.audit = this.screenAudit
      if (this.startDayStr) param.created_at_mt = this.startDayStr + ' 00:00'
      if (this.endDayStr) param.created_at_lt = this.endDayStr + ' 23:59'
      if (this.auditStartDayStr) param.audit_time_mt = this.auditStartDayStr + ' 00:00'
      if (this.auditEndDayStr) param.audit_time_lt = this.auditEndDayStr + ' 23:59'
      StageAudit.index(param).then(res => {
        this.loading = false
        if(res.data.lists.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.lists.data || [])
      })
    },
    onSearch () {
      this.list = []
      this.onLoad()
      this.showRight = false
    },
    onClickRight() {
      this.showRight = true
    },
    setDate (type) {
      switch (type) {
        case 'created':
          this.startDayStr = this.dateFormatStr(this.currentDate.startDay)
          this.endDayStr = this.dateFormatStr(this.currentDate.endDay)
          this.showTime = false
          break
        case 'audit':
          this.auditStartDayStr = this.dateFormatStr(this.currentDate.auditStartDay)
          this.auditEndDayStr = this.dateFormatStr(this.currentDate.auditEndDay)
          this.showAuditTime = false
          break
      }
     
    },
    dateFormatStr (time) {
      var Y = time.getFullYear() + '-';
      var M = (time.getMonth() + 1 < 10 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1) + '-';
      var D = (time.getDate() < 10 ? '0' + time.getDate() : time.getDate());
      return Y + M + D
    },
    clearTime (type) {
      switch (type) {
        case 'created':
          this.startDayStr = ''
          this.endDayStr = ''
          break
        case 'audit':
          this.auditStartDayStr = ''
          this.auditEndDayStr = ''
          break
      }
    },
    formatter(type, val) {
      if (type === 'year') {
        return val + '年';
      }
      if (type === 'month') {
        return val + '月';
      }
      if (type === 'day') {
        return val + '日';
      }
      return val;
    },
    activeq (v) {
      this.screenAudit = v
    }
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {
    // background: #f4f5f7;
    padding: 16px 12px;

    .list-item {
      margin-bottom: 12px;
      border: 1px solid transparent;
      padding: 12px 12px;
      background-color: #fff;
      border-radius: 12px;
      font-size: 14px;
      color: #585757;
      position: relative;

      >.van-tag {
        position: absolute;
        right: 12px;
        top: 20px;
      }

      &-content {
        display: flex;
        margin-bottom: 6px;

        >div {
          flex: 1;
        }
      }

      .hand {
        border-top: 1px solid #f4f5f7;
        text-align: center;
        margin-bottom: 0;
        margin-top: 10px;
        padding-top: 12px;

        button {
          width: 80%;
          font-size: 14px;
        }
      }
      // box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

      // table {
      //   width: 100%;

      //   th {
      //     width: 100px;
      //   }
      // }
    }
      
  }

  .screen {
    height: 100%;
    padding: 12px;
    background-color: #f4f5f7;

    .list-item {
      margin-bottom: 20px;
    }

    .item-title {
      font-weight: bold;
    }

    .item-content {
      width: 100%;
      background-color: #fff;
      /* background: red; */
      border: 1px solid #E5E6E7;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
      border-radius: 7px;
      margin-top: 10px;
    }

    .item-lable {
      display: inline-block;
      width: auto;
      align-items: center;
      line-height: 36px;
      margin: 10px 3px;
      border-radius: 0;
    }

    .qd{
      color: #1890ff!important;
      border-color: #1890ff!important;
      background-color: #f3f9ff!important;
    }
    
  }

  .van-picker {
    margin-bottom: 20px;
  }

  .showSelectDayBtn {
    padding: 10px 5px;
    display: flex;

    button {
      flex: 1;
      margin:  0 5px;
    }
  }
}
</style>
